<!--
 * @Author: zx
 * @Date: 2022-09-02 16:42:55
 * @LastEditTime: 2022-12-30 14:37:53
 * @Description: 
 * @LastEditors: zx
-->
<!--
 * @Author: zx
 * @Date: 2022-08-29 10:30:48
 * @LastEditTime: 2022-09-02 16:41:38
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="project-tree">
    <div class="expand-switch" :class="{ 'is-open': isOpen }" @click="isOpen = !isOpen">
      <svg class="expand-icon">
        <use xlink:href="#iconshuangjiantou_shang"/>
      </svg>
    </div>
    <div v-if="isOpen" class="project-tree-main">
      <div class="search-input">
        <el-input
          placeholder="输入关键字进行筛选"
          v-model="filterText">
        </el-input>
      </div>
      <div class="tree-list">
        <el-tree
          ref="treeRef"
          v-loading="loading"
          :data="treeData"
          default-expand-all
          node-key="id"
          :props="defaultProps"
          style="padding: 0 5px;height: 100%"
          :filter-node-method="filterNode"
          @node-click="treeSelectClick"
        >
          <template #default="{ node, data }">
            <div class="archi-label">
              <span class="archi-label-svg">
                <svg aria-hidden="true" class="color-icon">
                  <use :xlink:href="getIcon(data)" />
                </svg>
                <el-tooltip
                  v-if="node && data[defaultProps.label].length > 12"
                  :content="node ? data[defaultProps.label] : ''"
                  effect="dark"
                  :open-delay="500"
                  placement="top"
                >
                  <span>{{ node ? data[defaultProps.label] : '' }}</span>
                </el-tooltip>
                <span v-else>{{ node ? data[defaultProps.label] : '' }}</span>
              </span>
            </div>
          </template>
        </el-tree>
      </div>
    </div>
  </div>
</template>
<script>
import { getMapList } from '@/api/saasManage'
import { Tree, Tooltip } from 'element-ui'
export default {
  components:{
    'el-tree': Tree,
    'el-tooltip': Tooltip,
  },
  props:{
    defaultProps: {
      type: Object,
      default: () => {
        return {
          label: 'name',
          children: 'children'
        }
      }
    },
    /* 允许选择的架构类型， 1 2 集团 | 3 4 公司 | 5 6 项目 */
    filterType: {
      type: Array,
      default: () => {
        return [1, 2, 3, 4, 5, 6]
      }
    },
    /* 是否显示部门架构 */
    showSection: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: true, // 是否展开
      treeData: [], // 树状数据
      filterText: '', // 搜索字段
      loading: false
    }
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    }
  },
  created() {
    this.getArchiTreeList()
  },
  methods:{
    handleClick(boo) {
      this.isOpen = boo
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    treeSelectClick(data) {
      if (this.filterType.indexOf(+data.type) !== -1) {
        this.$emit('chooseArchi', data)
      }
    },
    /* 根据架构类型 返回不同图标 */
    getIcon(data) {
      const group = [1, 2]
      const company = [3, 4]
      const project = [5, 6]
      const section = [7]
      if (!data) {
        return '#iconjituan'
      }
      if (group.indexOf(+data.type) !== -1 ) {
        return '#iconjituan'
      } else if (company.indexOf(+data.type) !== -1 ) {
        return '#icongongsi'
      } else if (project.indexOf(+data.type) !== -1 ) {
        return '#iconxiangmu'
      } else if (section.indexOf(+data.type) !== -1 ) {
        return '#iconbumen'
      }
    },
    /* 获取架构树数据 */
    getArchiTreeList() {
      this.loading = true
			getMapList().then((res) => {
        this.loading = false
				if (res.status === 200 && res.data.code === 200) {
          const data = res.data?.data?.data
          const chooseArr = [1, 2, 3, 4, 5, 6]
          const loopTree = (arr) => {
            arr.forEach(element => {
              if (element.children && element.children.length) {
                element.children = element.children.filter((ite) => {
                  if (this.showSection) {
                    return true
                  } else {
                    return chooseArr.indexOf(+ite.type) !== -1
                  }
                })
                loopTree(element.children)
              }
            });
          }
          if (data && data.length) {
            loopTree(data)
          }
          this.treeData = data
				}
			})
    },
  }
}
</script>
<style lang='less' scoped>
.project-tree {
  position: relative;
  width: auto;
  .shrink-icon{
    right: 0;
    position: absolute;
    z-index: 999;
    font-size: 22px;
    top: 14px;
  }
  .zhankai-icon{
    right: -20px;
    position: absolute;
    z-index: 999;
    font-size: 22px;
    top: 14px;
  }
  .project-tree-main {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transition: width .5s;
    .search-input {
      width: 90%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .tree-list {
      width: 100%;
      flex: 1;
      overflow-y: auto;
      .archi-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        -moz-user-select:none; /* Firefox私有属性 */
        -webkit-user-select:none; /* WebKit内核私有属性 */
        -ms-user-select:none; /* IE私有属性(IE10及以后) */
        -khtml-user-select:none; /* KHTML内核私有属性 */
        -o-user-select:none; /* Opera私有属性 */
        user-select:none; /* CSS3属性 */
        .archi-label-svg {
          width: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          span {
            flex: 1;
            overflow: hidden;
            padding-right: 6px;
            box-sizing: border-box;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
        }
      }
      .color-icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
}
.expand-switch {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
  width: 25px;
  height: 25px;
  transform: translateX(100%);
  transition: all .5s;
  box-shadow: 2px 2px 5px 3px #ccc;
  border-radius: 0 50% 50% 0;
  background: #fff;

  .expand-icon {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
    transition: all .5s;
  }
}

.is-open {
  width: 25px;
  height: 25px;
  transform: translateX(50%);
  border-radius: 50%;
  visibility: hidden;

  .expand-icon {
    transform: rotate(-90deg);
    visibility: hidden;
  }
}

&:hover {
  .is-open {
    transition: all .5s;
    visibility: visible;

    .expand-icon {
      visibility: visible;
    }
  }
}
</style>