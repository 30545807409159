<!--
 * @Author: zx
 * @Date: 2022-09-02 17:49:35
 * @LastEditTime: 2023-01-06 10:01:51
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="item-info">
    <div v-if="!showEdit" class="item-info-show"> <!-- projectInfo -->
      <div class="item-info-title">
        <p> {{ archiData.name }} </p>
        <i class="iconfont iconshezhi" @click="showEditClick()"></i>
      </div>
      <div class="info-content">
        <el-carousel
          v-if="
            projectInfo &&
            projectInfo.photos != null &&
            projectInfo.photos.length != 0
          "
          arrow="always"
          :interval="5000"
          style="height: 160px; overflow: hidden"
        >
          <el-carousel-item
            v-for="(item, index) in projectInfo.photos"
            :key="index"
            style="height: 100%"
          >
            <el-image
              fit="fill"
              :src="item.url"
              style="width: 100%; height: 100%"
              @click="showPreview(true, item.url)"
            />
          </el-carousel-item>
        </el-carousel>
        <div class="info-content-form">
          <div class="info-item">
            <span class="project-key">项目名称</span>
            <span class="project-label">{{ projectInfo.name }}</span>
          </div>
          <div class="info-item">
            <span class="project-key">项目简称</span>
            <span class="project-label">{{ projectInfo.short_name }}</span>
          </div>
          <div class="info-item" v-for="(item) in baseLabelList" :key="item.key">
            <template v-if="item.type === 'input'">
              <span>{{item.name}}</span>
              <span class="item-value">{{projectInfo[item.key] || '-'}}</span>
            </template>
            <template v-if="item.type === 'price'">
              <span>{{item.name}}</span>
              <span v-if="projectInfo[item.key]" class="item-value">{{projectInfo[item.key] || '-'}}亿元</span>
            </template>
            <template v-if="item.type === 'select'">
              <span>{{item.name}}</span>
              <span
                class="item-value" 
                :style="projectInfo[item.key] === '-1' ? 'color: #ed4014' : 'color: #0076ff'"
              >
                <span v-if="+projectInfo.china_if === 1 || +projectInfo.china_if == 0">{{+projectInfo.china_if === 1 ? '国内' : '国外'}}</span>
                {{ getStatus (projectInfo[item.key])|| '-'}}
              </span>
            </template>
            <template v-if="item.type === 'status'">
              <span>{{item.name}}</span>
              <span class="item-value" :style="+projectInfo[item.key] === 0 ? 'color: #ed4014' : 'color: #0076ff'">{{getMapStatus(projectInfo[item.key])}}</span>
            </template>
          </div>
          <div class="info-item-unit" v-for="(item) in unitList" :key="item.key">
              <div>{{item.name}}</div>
              <div>{{projectInfo[item.key] || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showEdit" v-loading="loading" class="item-info-show item-info-edit">
      <div class="item-info-title">
        <p> 编辑项目概况 </p>
      </div>
      <el-form
        ref="ruleForm"
        class="demo-ruleForm scroller"
        :model="ruleForm"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="ruleForm.name" />
        </el-form-item>
        <el-form-item label="项目简称" prop="short_name">
          <el-input v-model="ruleForm.short_name" />
        </el-form-item>
        <el-form-item label="项目等级">
          <el-input v-model="ruleForm.project_level" />
        </el-form-item>
        <el-form-item label="项目类型">
          <el-input v-model="ruleForm.project_nature" />
        </el-form-item>
        <el-form-item label="项目运营性质" prop="project_operate">
          <el-input v-model="ruleForm.project_operate" />
        </el-form-item>
        <el-form-item label="建设状态">
          <el-radio-group v-model="ruleForm.build_status">
            <el-radio-button :label="'0'">
              在建
            </el-radio-button>
            <el-radio-button :label="'1'">
              收尾
            </el-radio-button>
            <el-radio-button :label="'2'">
              竣工
            </el-radio-button>
            <el-radio-button :label="'-1'">
              停工
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="企业地图显示" width="120">
          <el-switch
            v-model="ruleForm.is_map_show"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="国内项目" width="120">
          <el-switch
            v-model="ruleForm.china_if"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="主管总工">
          <el-input v-model="ruleForm.project_director" />
        </el-form-item>
        <el-form-item label="执行责任人">
          <el-input v-model="ruleForm.project_implementor" />
        </el-form-item>
        <el-form-item label="监理责任人" prop="zeren_person_code">
          <el-input v-model="ruleForm.zeren_person_code" />
        </el-form-item>
        <el-form-item label="施工责任人" prop="person_code">
          <el-input v-model="ruleForm.person_code" />
        </el-form-item>
        <el-form-item label="项目代码" prop="project_code">
          <el-input v-model="ruleForm.project_code" />
        </el-form-item>
        <el-form-item label="标段代码" prop="section_code">
          <el-input v-model="ruleForm.section_code" />
        </el-form-item>
        <el-form-item label="行业类型" prop="industry_type">
          <el-input
            v-model="ruleForm.industry_type"
            placeholder="请填写行业类型"
          />
        </el-form-item>
        <el-form-item label="项目总造价" prop="total_price">
          <el-input
            v-model="ruleForm.total_price"
            placeholder="亿元"
          />
        </el-form-item>
        <el-form-item label="施工合同号" prop="construction_contract">
          <el-input
            v-model="ruleForm.construction_contract"
          />
        </el-form-item>
        <el-form-item label="开工时间" required>
          <el-date-picker
            v-model="ruleForm.start_date"
            placeholder="选择日期"
            style="width: 100%"
            type="date"
            placement="bottom-start"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="完工时间" required>
          <el-date-picker
            v-model="ruleForm.end_date"
            placeholder="选择日期"
            style="width: 100%"
            type="date"
            placement="bottom-start"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="项目位置">
          <el-input
            v-model="ruleForm.build_address"
            placeholder="格式：中国/广东省/广州市/海珠区"
            style="width: 267px; margin-right: 15px"
          />
          <el-button size="small" @click="getmapPostion(true)">
            获取位置
          </el-button>
        </el-form-item>
        <el-form-item label="建设单位" prop="build_unit">
          <el-input
            v-model="ruleForm.build_unit"
          />
        </el-form-item>
        <el-form-item label="监理单位" prop="supervisor_unit">
          <el-input
            v-model="ruleForm.supervisor_unit"
          />
        </el-form-item>
        <el-form-item label="设计单位" prop="design_unit">
          <el-input
            v-model="ruleForm.design_unit"
          />
        </el-form-item>
        <el-form-item label="勘察单位" prop="survey_unit">
          <el-input
            v-model="ruleForm.survey_unit"
          />
        </el-form-item>
        <el-form-item label="施工单位" prop="construction_unit">
          <el-input
            v-model="ruleForm.construction_unit"
          />
        </el-form-item>
        <el-form-item label="项目概况" prop="content">
          <el-input
            v-model="ruleForm.content"
            rows="5"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="项目相册">
          <el-upload
            accept=".jpg,.jpeg,.png"
            :action="
              baseURL +
                'api/mapi?__method_name__=saasFile&token=' +
                token
            "
            :file-list="ruleForm.photos"
            :headers="{
              metadatatoken: metadataToken,
            }"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
          >
            <i class="el-icon-plus" />
          </el-upload>
        </el-form-item>
        <el-form-item label="负责人头像">
          <el-upload
            accept=".jpg,.jpeg,.png"
            :action="
              baseURL +
                'api/mapi?__method_name__=saasFile&token=' +
                token
            "
            :file-list="ruleForm.person_photo"
            :headers="{
              metadatatoken: metadataToken,
            }"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemoveAvatar"
            :on-success="handleSuccessAvatar"
          >
            <i class="el-icon-plus" />
          </el-upload>
        </el-form-item>
        <el-form-item label="智工key" prop="access_key">
          <el-input
            v-model="ruleForm.access_key"
          />
        </el-form-item>
        <el-form-item label="智工secret" prop="secret_key">
          <el-input
            v-model="ruleForm.secret_key"
          />
        </el-form-item>
      </el-form>
      <div class="drawer-btn">
        <el-button
          @click="
            showEdit = false;
            getmapPostion(false)
          "
        >
          取消
        </el-button>
        <el-button type="primary" @click="onSubmit">
          保存
        </el-button>
      </div>
    </div>
    <el-dialog
      title="图片预览"
      :visible.sync="imgPreview"
      append-to-body
      fullscreen
      lock-scroll
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%"
      @close="showPreview(false)"
    >
      <el-image
        fit="fill"
        :src="imgUrl"
        style="width: 100%; height: 100%"
      />
    </el-dialog>
  </div>
</template>
<script>
  import { getToken } from '@/utils/tools';
	import { baseUrl } from '@/libs/util';
  import { 
    Form,
    FormItem,
    Carousel,
    CarouselItem,
    Image,
    // Select,
    // Option,
    RadioGroup,
    RadioButton,
    Switch,
    DatePicker,
    Upload,
  } from 'element-ui'
  import { editMapList } from '@/api/saasManage'
  export default {
    components:{
      'el-form': Form,
      'el-form-item': FormItem,
      'el-carousel': Carousel,
      'el-carousel-item': CarouselItem,
      'el-image': Image,
      // 'el-select': Select,
      // 'el-option': Option,
      'el-radio-group': RadioGroup,
      'el-radio-button': RadioButton,
      'el-switch': Switch,
      'el-date-picker': DatePicker,
      'el-upload': Upload,
    },
    props:{
      archiData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        loading: false,
        baseURL: baseUrl(),
        metadataToken: process.env.VUE_APP_METATOKEN,
        token: getToken(),
        projectInfo: {},
        showEdit: false,
        imgUrl: '',
        imgPreview: false,
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ],
          project_code: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ],
          section_code: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ],
          zeren_person_code: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ],
          person_code: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ],
          content: [
            { required: true, message: '请输入概况', trigger: 'blur' }
          ],
          total_price: [
            {
              required: true,
              message: '请输入总造价',
              trigger: 'blur'
            }
          ],
          construction_contract: [
            {
              required: true,
              message: '请输施工合同号',
              trigger: 'blur'
            }
          ],
          industry_type: [
            {
              required: true,
              message: '请填写行业类型',
              trigger: 'change'
            }
          ],
          end_date: [
            {
              type: 'date',
              required: true,
              message: '请选择日期',
              trigger: 'change'
            }
          ],
          start_date: [
            {
              type: 'date',
              required: true,
              message: '请选择时间',
              trigger: 'change'
            }
          ]
        },
        ruleForm: {
          name: '',
          short_name: '',
          build_status: '0',
          industry_type: '',
          total_price: '',
          construction_contract:'',
          build_address: '',
          start_date: '',
          end_date: '',
          content: '',
          photos: [],
          lnglat: '',
          zeren_person_code: '',
          person_code: '',
          section_code: '',
          project_code: '',
          is_map_show: 1,
          china_if: 1,
          project_nature: '',
          project_operate: '',
          project_level: '',
          project_director: '',
          project_implementor: '',
          person_photo: [], // 项目负责人头像
          access_key: '', // 智慧工地key
          secret_key: '', // 智慧工地secret
        },
        // 显示信息循环数组
        baseLabelList: [
          { type: 'input', name: '项目等级', key: 'project_level' },
          { type: 'input', name: '项目类型', key: 'project_nature' },
          { type: 'input', name: '项目运营性质', key: 'project_operate' },
          { type: 'select', name: '建设状态', key: 'build_status' },
          { type: 'status', name: '企业地图', key: 'is_map_show' },
          { type: 'input', name: '主管总工', key: 'project_director' },
          { type: 'input', name: '执行责任人', key: 'project_implementor' },
          { type: 'input', name: '监理责任人', key: 'zeren_person_code' },
          { type: 'input', name: '施工责任人', key: 'person_code' },
          { type: 'input', name: '项目代码', key: 'project_code' },
          { type: 'input', name: '标段代码', key: 'section_code' },
          { type: 'input', name: '行业类型', key: 'industry_type' },
          { type: 'price', name: '项目总造价', key: 'total_price' },
          { type: 'input', name: '施工合同号', key: 'construction_contract' },
          { type: 'input', name: '开工时间', key: 'start_date',  },
          { type: 'input', name: '完工时间', key: 'end_date' },
          { type: 'input', name: '智慧工地key', key: 'access_key' },
          { type: 'input', name: '智慧工地secret', key: 'secret_key' },
        ],
        unitList: [
          { name: '建设单位', key: 'build_unit' },
          { name: '监理单位', key: 'supervisor_unit' },
          { name: '设计单位', key: 'design_unit' },
          { name: '勘察单位', key: 'survey_unit' },
          { name: '施工单位', key: 'construction_unit' },
          { name: '项目概况', key: 'content' },
        ]
      }
    },
    watch: {
      archiData: {
        handler(val) {
          this.showEdit = false
          if (val.id && [5, 6].indexOf(+val.type) !== -1) {
            this.projectInfo = JSON.parse(JSON.stringify(val))?.info ? JSON.parse(JSON.stringify(val)).info : {}
          }
        },
        deep: true,
        immediate: true
      }
    },
    mounted() {
      this.projectInfo = JSON.parse(JSON.stringify(this.archiData))?.info ? JSON.parse(JSON.stringify(this.archiData)).info : {}
      // if (this.projectInfo && this.projectInfo.photos && this.projectInfo.photos.length) {
      //   this.projectInfo.photos.forEach(element => {
      //     this.imgList.push(element.url)
      //   });
      // }
    },
    methods:{
      /**
       * 获取建设状态
       */
      getStatus(type){
        switch(type){
          case '0':
            return'在建'
          case '1':
            return '收尾'
          case '2':
            return '竣工'
          case '-1':
            return'停工'
        }
        return ''
      },
      // 预览图片 
      showPreview(boo, url) {
        if (boo) {
          this.imgUrl = url
        }
        this.imgPreview = boo
      },
      /* 点击打开编辑 */
      showEditClick() {
        if (this.projectInfo) {
          this.ruleForm.name = this.archiData.name
          if (Object.keys(this.projectInfo).length) {
            Object.keys(this.projectInfo).forEach(element => {
              if (this.projectInfo[element]) {
                this.ruleForm[element] = this.projectInfo[element]
              }
              if (element === 'photos' || element === 'person_photo') {
                this.ruleForm[element] = this.projectInfo[element] || []
              }
            });
          }
        }
        this.showEdit = true
      },
      /* 图片预览 */
      handlePictureCardPreview(file) {
        this.imgUrl = file.url
        this.imgPreview = true
      },
      /* 上传图片回调 */
      handleSuccess (res) {
        if (res.code === 200) {
          this.ruleForm.photos.push(res.data)
        }
      },
      /* 删除图片回调 */
      handleRemove (file) {
        if (this.ruleForm.photos.length === 0) {
          this.ruleForm.photos = []
        } else {
          this.ruleForm.photos.forEach((item, index) => {
            if (item.id === file.id) {
              this.ruleForm.photos.splice(index, 1)
            }
          })
        }
      },
      // 头像操作
      handleSuccessAvatar (res) {
        if (res.code === 200) {
          this.ruleForm.person_photo.push(res.data)
        }
      },
      /* 移除图片操作 */
      handleRemoveAvatar (file) {
        if (this.ruleForm.person_photo.length === 0) {
          this.ruleForm.person_photo = []
        } else {
          this.ruleForm.person_photo.forEach((item, index) => {
            if (item.id === file.id) {
              this.ruleForm.person_photo.splice(index, 1)
            }
          })
        }
      },
      /* 编辑项目数据提交 */
      onSubmit() {
        this.loading = true
         const data = {
          name: this.ruleForm.name,
          info: this.ruleForm
        }
        editMapList(data,this.archiData.id).then((res) => {
          this.loading = false
          if (res.status === 200 && res.data.code === 200) {
            this.$message.success('保存成功!');
            this.$emit('changeProject', res.data.data)
            this.getmapPostion(false)
          }
        })
      },
      /* 获取项目位置数据 */
      getmapPostion(boo) {
        this.$emit('getmapPostion', boo)
      },
      /* 设置地址 */
      setLacation (data) {
        this.ruleForm.lnglat = data
      },
      setAddress (area, address) {
        this.ruleForm.build_address = area
        this.ruleForm.position = address
      },
      /* 获取显示隐藏状态 */
      getMapStatus (status) {
        if (+status === 0) {
          return '隐藏'
        } else if (+status === 1) {
          return '显示'
        }
        return '-'
      },
    }
  }
</script>
<style lang='less' scoped>
  .item-info {
    width: auto;
    height: 100%;
    padding: 12px;
    box-sizing: border-box;
    .item-info-show {
      width: 230px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .item-info-title {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          font-size: 18px;
        }
      }
      .info-content {
        flex: 1;
        overflow-y: auto;
        &::-webkit-scrollbar { 
          width: 0 !important 
        }
        .info-content-form {
          width: 100%;
          height: auto;
          .info-item {
            display: flex;
            justify-content: space-between;
            padding: 12px 0;
            border-top: 1px solid #f6f6f6;
            .item-value {
              flex: 1;
              padding-left: 10px;
              box-sizing: border-box;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: right;
              span {
                color: #000;
              }
            }
          }
          .info-item-unit {
            display: flex;
            flex-direction: column;
            div:nth-child(1) {
              padding: 12px 0;
              border-top: 1px solid #f6f6f6;
              position: relative;
              padding-left: 8px;
              box-sizing: border-box;
              &::before {
                content: "";
                height: 14px;
                width: 3px;
                position: absolute;
                left: 0;
                top: 13px;
                background-color: #0076ff;
              }
            }
            div:nth-child(2) {
              flex: 1;
              padding: 12px 0 12px 10px;
              box-sizing: border-box;
              border-top: 1px solid #f6f6f6;
              line-height: 30px;
            }
          }
        }
      }
    }
    .item-info-edit {
      width: 500px;
      .demo-ruleForm {
        padding: 0 15px;
        height: calc(100% - 65px);
        overflow-y: auto;
      }
      .drawer-btn {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
</style>