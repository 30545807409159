<!--
 * @Author: zx
 * @Date: 2022-08-29 09:53:41
 * @LastEditTime: 2022-12-30 15:38:31
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="project">
    <div class="project-tree">
      <!-- 只允许选择项目 -->
      <ProjectTree ref="projectTree" :showSection="false" @chooseArchi="chooseArchi" />
    </div>
    <div class="project-content">
      <!-- <div class="project-search">
        列表顶部搜索占位区 
      </div> -->
      <div class="project-map">
        <MapComponent :archiData="currentArchi" @changeProject="changeProject" />
      </div>
    </div>
  </div>
</template>
<script>
import MapComponent from './MapComponent'
import ProjectTree from '@/manage-views/components/projectTree/Index.vue'
export default {
  components:{
    MapComponent, // 地图组件
    ProjectTree // 项目树数据
  },
  props:{
  },
  data() {
    return {
      currentArchi: null
    }
  },
  mounted() {
  },
  methods:{
    /* 选择架构 */
    chooseArchi(data) {
      this.currentArchi = data
    },
    /* 修改项目数据，提交后数据保存 */
    changeProject(data) {
      this.$refs.projectTree.getArchiTreeList()
      this.currentArchi = data
      if (data.info) {
        this.currentArchi.info = JSON.parse(this.currentArchi.info)
      }
    }
  }
}
</script>
<style lang='less' scoped>
.project {
	display: flex;
	box-sizing: border-box;
	padding: 8px;
	width: 100%;
	height: 100%;
	background-color: #fff;
	.project-tree {
		box-sizing: border-box;
		width: auto;
		height: 100%;
	}
	.project-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		.project-search {
			display: flex;
			justify-content: space-between;
			width: 100%;
			height: 60px;
			background-color: #fff;
		}
		.project-map {
			flex: 1;
			box-sizing: border-box;
			width: 100%;
			background-color: #f2f4f5;
		}
	}
}

</style>