<!--
 * @Author: zx
 * @Date: 2022-08-29 09:59:09
 * @LastEditTime: 2023-01-06 10:07:09
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="map">
    <div id="viewer" />
    <NumCont
      v-for="(item, index) in contList"
      :key="index"
      :style="{ top: item.top, left: item.left }"
      :info="item"
      :mapSize="mapSize"
      @changeMapSize="changeMapSize"
    >
    </NumCont>
    <div v-if="archiData && archiData.id && [5, 6].indexOf(+archiData.type) !== -1" class="map-cicle">
      <ItemInfo 
        v-if="archiData && archiData.id && [5, 6].indexOf(+archiData.type) !== -1" :archiData="archiData" 
        ref="infoItem"
        @changeProject="changeProject"
        @getmapPostion="getmapPostion"
      />
    </div>
  </div>
</template>
<script>
import map from "@/assets/js/map2d.js";
import ItemInfo from "./components/ItemInfo"; // 项目弹窗信息
import NumCont from "./components/NumCont"; // 项目定位点详情
import request from '@/apis/http/saasRequest.js'
let map2d = null;
export default {
  components: {
    ItemInfo,
    NumCont
  },
  props: {
    archiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      mapSize: 4, //地图初始的层级
      projectList: [], // 当前选择的架构的项目列表
      timer: null,
      contList: [], // 定位点数据
    };
  },
  watch: {
    archiData: {
      handler(val) {
        this.contList = []
        if (val) {
          if ([1, 2, 3, 4].indexOf(+val.type) !== -1) {
            this.getAllProject()
          } else if ([5, 6].indexOf(+val.type) !== -1) {
            this.getAllProject([val])
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    map2d = new map.Viewer2DGIS(
      "viewer",
      "20191204141039-1089838992-5de74ddfe3a2c.gislayer2d"
    );
    window.map2d = map2d;
    map2d.on("viewerPreLoad", () => {
      // 初始化GIS模块
      map2d.initionMap({ isFence: false,isSearch: false,
        isMeasure: false,
        isLayer: false, });
      map2d.on("move", async () => {
        this.mapSize = await map2d.getZoom();
        this.map2dMove();
      });
      if(this.contList && this.contList.length) {
        this.contList.forEach(async element => {
          const point = await map2d.latLngToContainerPoint({ lat: element.lnglat.latitude, lng: element.lnglat.longitude })
          element.left = point.x - 44 + 'px'
          element.top = point.y - 44 + 'px'
        });
      }
      map2d.emitMove();
    });
  },
  methods: {
    //监听模型是否加载完毕
    map2dMove() {
      if (map2d && map2d !== null) {
        this.$nextTick(() => {
          if(this.contList && this.contList.length) {
            this.contList.forEach(async element => {
              const point = await map2d.latLngToContainerPoint({ lat: element.lnglat.latitude, lng: element.lnglat.longitude })
              element.left = point.x - 44 + 'px'
              element.top = point.y - 44 + 'px'
            });
          }
        });
      }
    },
    /* 获取集团&公司下的所有项目 */
    getAllProject(arr) {
      this.projectList = []
      const loopArchi = (array) => {
        array.forEach(element => {
          if(element && [5, 6].indexOf(+element.type) !== -1) {
            this.projectList.push(element)
          }
          if(element.children && element.children.length) {
            loopArchi(element.children)
          }
        });
      }
      if (arr && arr.length) {
        loopArchi(arr)
      } else {
        loopArchi([this.archiData])
      }
      if (this.projectList && this.projectList.length) {
        this.contList = []
        this.projectList.forEach( async element => {
          if (element.info && element.info.name && element.info.lnglat) {
            let point = {}
            if (map2d) {
              point = await map2d.latLngToContainerPoint({ lat: element.info.lnglat.latitude, lng: element.info.lnglat.longitude })
            }
            this.contList.push({
              name: element.info.short_name || element.info.name,
              lnglat: element.info.lnglat,
              left: point ? point.x - 44 + 'px' : null,
              top:  point ? point.y - 44 + 'px' : null,
              start_date: element.info.start_date || '-',
              end_date: element.info.end_date || '-',
              project_nature: element.info.project_nature || '-',
              total_price: element.info.total_price || '-',
              build_address: element.info.build_address || '-',
            })
          }
        });
      } else {
        this.contList = []
      }
    },
    /* 修改项目数据回调 */
    changeProject(data) {
      this.$emit('changeProject', data)
    },
    /* 获取地图位置数据 */
    getmapPostion (bol) {
      if (map2d || map2d !== null) {
        map2d.setWidgetDisplay('ViewerGIS_stamp', bol)
        map2d.on('stampCoordinates', (val) => {
          // 触发多次
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            if (val && bol) {
              const obj = { latitude: val.lat, longitude: val.lng }
              this.$refs.infoItem.setLacation(obj)
              const url = `https://api.tianditu.gov.cn/geocoder?postStr={'lon':${val.lng},'lat':${val.lat},'ver':1}&type=geocode&tk=c5aaa7bebd44c40e6e03f73df75582d2`
              request({url, method: 'GET', params: {}}).then((res) => {
                if (res.status === 200 && res.data.result && res.data.result.addressComponent) {
                  const data = res.data.result.addressComponent
                  let area = ''
                  if (data.nation) {
                    if (data.nation === '中国' || data.nation === '全球') {
                      this.$refs.infoItem.ruleForm.china_if = 1
                    } else {
                      this.$refs.infoItem.ruleForm.china_if = 0
                    }
                  }
                  if (data.province && data.city) {
                    area = data.province + '/' + data.city + '/' + data.county
                  } else {
                    area = data.nation + '/'
                    this.$message.warning('位置信息不全，手动填写格式：省/市/区')
                  }
                  const address = data.province + data.city + data.county + data.address
                  if (address) {
                    this.$refs.infoItem.setAddress(
                      area,
                      address
                    )
                  }
                }
              })
            }
          }, 200)
        })
      }
    },
    changeMapSize(lnglat) {
      const obj = [
        lnglat.latitude,
        lnglat.longitude
      ]
      if (this.mapSize < 12 ) {
        map2d.setView(obj, 12)
      } else {
        map2d.setView(obj, 4)
      }
    }
  },
};
</script>
<style lang='less' scoped>
  .map {
    width: 100%;
    height: 100%;
    position: relative;
    #viewer {
      width: 100%;
      height: 100%;
    }
    .map-cicle {
      position: absolute;
      height: calc(100% - 32px);
      top: 16px;
      left: 16px;
      background-color: #fff;
      width: auto;
    }
  }
</style>