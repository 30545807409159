<!--
 * @Author: zx
 * @Date: 2022-09-16 11:43:20
 * @LastEditTime: 2022-09-16 16:05:44
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="num-cont">
    <div v-if="mapSize < 12" class="num-cont-circle" @click="circleClick(info.lnglat)">
      <i class="iconfont iconxuanze-xiangmu"></i>
      <span>{{info.name}}</span>
    </div>
    <div v-else class="num-cont-detail" @click="circleClick(info.lnglat)">
      <div class="num-cont-circle">
        <i class="iconfont iconxuanze-xiangmu"></i>
      </div>
      <div class="num-cont-info">
        <p>
          {{ info.name }}
        </p>
        <ul style="padding: 0">
          <li>
            <i class="iconfont icongongsi1" />
            预计施工
            <span>{{info.start_date ? (info.start_date).substring(0,10) +' 至 '+ info.end_date.substring(0,10): '-'}}</span>
          </li>
          <li>
            <i class="iconfont iconzhuanxiangfenlei" />
            工程类型
            <span>{{info.build_address ? info.build_address: '-'}}</span>
          </li>
          <li>
            <i class="iconfont iconcaiwuguanli" />
            合同造价
            <span>{{info.total_price ? info.total_price + '亿' : '-'}}</span>
          </li>
          <li>
            <i class="iconfont icondingwei1" />
            建设地址
            <span>{{info.position ? info.position: '-'}}</span>
          </li>
          <li>
            <i class="iconfont iconwenjianshenpi" />
            行政区域
            <span>{{info.build_address ? info.build_address: '-'}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    components:{},
    props:{
      mapSize: {
        type: Number,
        default: 4
      },
      info: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods:{
      /* 缩放地图 */
      circleClick(lnglat) {
        this.$emit('changeMapSize', lnglat)
      }
    }
  }
</script>
<style lang='less' scoped>
  .num-cont {
    position: absolute;    
    width: auto;
    height: auto;
    .num-cont-circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgb(15, 98, 254);
      color: #fff;
      i {
        flex: 1;
        font-size: 25px;
        line-height: 34px;
      }
      span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 16px;
      }
    }
    .num-cont-detail {
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      .num-cont-circle {
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: rgb(15, 98, 254);
        i {
          font-size: 25px;
        }
      }
      .num-cont-info {
        width: 300px;
        opacity: 0.8;
        padding: 8px;
        background: #fff;
        p {
          height: auto;
          margin-bottom: 6px;
          padding-bottom: 10px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #25282e;
          line-height: 14px;
          position: relative;
          border-bottom: 1px solid #eaebed;
        }
        li {
          text-align: left;
          height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            opacity: 1;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: bold;
            text-align: left;
            color: #25282e;
            margin-left: 8px;
          }
        }
      }
    }
  }
</style>